import React, {useState, useEffect} from 'react'
import { graphql, Link } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Post from '../templates/post/post'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import { FaAngleDoubleDown } from "@react-icons/all-files/fa/FaAngleDoubleDown";
import { FaAngleDoubleUp } from "@react-icons/all-files/fa/FaAngleDoubleUp";
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const StandardPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const posts = data.remark.posts
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const infplans_big_logo = data.infplans_big_logo?.childImageSharp?.fluid
  const section2_img_right = data.section2_img_right?.childImageSharp?.fluid
  const section2_img_left = data.section2_img_left?.childImageSharp?.fluid
  
  const img_doctor = data.img_doctor?.childImageSharp?.fluid
  const img_vip = data.img_vip?.childImageSharp?.fluid
  const img_smartdelay = data.img_smartdelay?.childImageSharp?.fluid
  const img_hero = data.img_hero?.childImageSharp?.fluid
  const img_worldhopper = data.img_worldhopper?.childImageSharp?.fluid

  const book_icon = data.book_icon?.childImageSharp?.fluid
  const book2_icon = data.book2_icon?.childImageSharp?.fluid
  const cta_bg = data.cta_bg?.childImageSharp?.fluid
  const [infBMIStatus, setInfBMIStatus] = useState(false);

  const phoneNumber = meta.phoneNumber
  const emailAddress = meta.emailAddress
  const emailAddressUrl = "mailto:"+emailAddress
  const phoneNumberUrl = "tel:"+phoneNumber


  function showInfBMI() {
    setInfBMIStatus(true);
  }

  function hideInfBMI() {
    setInfBMIStatus(false);
  }

  return (
    <Layout location={location}>
      <Helmet>
        <title>Hop! WorldHopper - Low Cost Travel Assistance</title>
        <meta name="description" content="Hop! WorldHopper is a low cost travel assistance package which covers pre-existing conditions"/>
        <meta name="keywords" content="Hop! WorldHopper, inf insurance, cheap insurance for visitors, kv rao insurance, kvrao insurance,insurance for visitors, visitor insurance usa, accident &amp; sickness insurance for visitors to usa, accident &amp; sickness insurance for visitors to usa, visitor accident &amp; sickness insurance usa, visitor accident &amp; sickness insurance, visitor insurance for parents, insurance for visitors for parents"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="standard-hero-section-bg"
        fluid={img_hero}
        alt="standard"
      >
        <div id="standard-hero-section">
          <Container>
            <div className="col-lg-12">
              <h1 className="text-white text-center">
                Hop! WorldHopper
              </h1>
              <h2 className="text-white text-center">
                Travel Assistance
              </h2>
              <p className="hero-text text-white text-center">
                              
             </p>
              <Link to="/inf-easy-select" className="nav-link">
                <button className="hero-btn"> Get a Quote </button>
              </Link>
            </div>
          </Container>
        </div>
      </BackgroundImage>

      <section id="standard-section1">
        <Container>
          <div className="standard-container">
            <h2 className="text-blue text-center">Why Hop! WorldHopper Plan?</h2>
            <div className="row mt-5">
              <div className="col-lg-8">
                              <p className="text-black">Hop! World Hopper is a travel assistance membership which provides travel, medical, legal, and personal assistance for travelers visiting anywhere worldwide outside their home country excluding the United States. This assistance membership provide assistance for eligible travel, medical (including pre-existing conditions), legal, and personal expenses for covered events, as per the terms and conditions listed in the membership subscription. Hop! World Hopper is a travel assistance membership program, and is not an insurance product.</p>
                              <p className="text-black">Hop! World Hopper covers stabilization and treatment of pre-existing conditions, per the terms listed in the general conditions document. The purpose of this travel assistance is to stabilize individuals for their pre-existing conditions until they are stable enough to return to their home country, excluding the United States.</p>
                              <p className="text-black">Hop! World Hopper features Hop! Assist which arranges for direct billing with in-network providers on behalf of members. Hop! Assist provides 24/7 responsive claims, emergency travel and medical assistance from any device, any time, any place. Hop! Assist will process your claim, coordinate with the medical providers around the globe, determine eligibility, and even handle evacuation and repatriation services.</p>				
                {infBMIStatus ?
                  <>
                                      <p>
                                          At Hop!, we understand that unexpected situations can arise while traveling, which is why we offer a range of services to help you navigate any travel-related issues that may come your way. Here are some of the features we offer to ensure that your travel plans stay on track:  </p>

                                      <p> Trip Cancellation:</p>
                                      <p>  We know that sometimes plans change, and you may need to cancel your trip. Our program offers trip cancellation assistance and provides reimbursement for non-refundable expenses. You have the ability to choose the level of Trip Cancellation assistance based on your membeship selection.</p>

                                      <p> Trip Delay:</p>
                                      <p>If your trip is delayed, we can help. Our program offers trip delay coverage to provide reimbursement for additional expenses incurred due to the delay, such as meals, transportation, and accommodations.</p>

                                          <p>Missed Connection / Coordination:</p>
                                      <p>If you miss a connection due to a delayed or cancelled flight, we can help coordinate alternative travel arrangements to get you to your destination as quickly as possible.</p>

                                          <p> Baggage Delay / Lost Bag Search:</p>
                                      <p> If your baggage is delayed, we can help you locate it and arrange for delivery to your location. Our program also offers coverage for expenses incurred due to baggage delay, such as the purchase of necessary clothing and toiletries.</p>

                                      <p> Lost Baggage / Lost Bag Search:</p>
                                      <p> If your baggage is lost, our program offers coverage to help replace your belongings and reimburse you for any necessary expenses incurred</p>

                                     <p>  Flight Rebooking:</p>
                                      <p>  If your flight is cancelled or delayed, we can help rebook your travel arrangements to get you to your destination as quickly and efficiently as possible.</p>


                                     <p> Legal Assistance:</p>
                                      <p> Our legal assistance team is available to provide you with legal advice and support while you travel. Whether you have questions about local laws and regulations, need assistance with visa or immigration issues, or require representation in legal proceedings, we have you covered</p>

                                      <p> Personal Assistance:</p>
                                      <p> Our personal assistance team is available to help with a wide range of non-medical needs while you travel. Whether you need help with language translation, making reservations or recommendations for restaurants and attractions, or arranging transportation, we are here to assist you.</p>

                                     <p> Medical Assistance:</p>
                                      <p>  Our medical assistance team is available to provide you with expert medical advice and support while you travel. We offer comprehensive medical coverage, including coverage for pre-existing conditions. If you require medical treatment while abroad, we can help arrange appointments with local doctors and hospitals, and even arrange medical transportation back to your home country if necessary.</p>
                                    <p>If you a visitors traveling within the United States, this travel assistance utilizes the United Healthcare Options PPO via Hop! Assist.</p>
                                     <p>This assistance membership is available to visitors ages 0-99 traveling to anywhere worldwide when purchased before the start date of your trip for at least 10 days and no more than 364 days, which is the Maximum Legnth of Membership. Enrollment can be completed Online.</p>
                                    
                <a href="javascript:void(0)" className="read-less" onClick={hideInfBMI}>READ LESS <FaAngleDoubleUp className="double-arrow-up" /></a>
              </>
              :
                <a href="javascript:void(0)" className="read-more" onClick={showInfBMI}>READ MORE <FaAngleDoubleDown className="double-arrow-down" /></a>
              }
            </div>
              <div className="col-lg-4">
                <Img fluid={img_worldhopper} className="big-logo"/>
                <h3 className="text-blue text-center">HOP! WORLDHOPPER TRAVEL ASSIST</h3>
                <Link to="/inf-easy-select"><button className="row-btn"> Click to Get a Quote </button></Link>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section id="standard-section2">
        <Container>
          <div className="col-lg-12">
            <h3 className="text-white text-center">As a member of Hop! WorldHopper, you will also have access to additional Features when traveling to the worldwide including:</h3>
          </div>
          <div className="col-lg-12">
            <div className="row mt-5">
              <div className="col-lg-5">
                <Img fluid={img_doctor} className="section2-img2"/>
              </div>
              <div className="col-lg-7">
                <ul>
                  <li className="text-white">As a plan member, you will be provided access to Doctor Please!- a telehealth feature which allows members to get medical care from the comfort of their own home. While anywhere worldwide, Doctor Please! allows members to schedule an appointment with a healthcare provider that is credentialed, certified and highly trained, and typically arrange medical care within 1 hour. The doctor will video or audio chat with you and if required, send a prescription to the nearest pharmacy (note that prescriptions may require out of pocket expense if they are not eligible expenses). Doctor Please! can be used for any non life threatening condition. This is not an insurance product and is not insured by Tideview Risk PCC. Doctor Please! only provides access to and helps arrange the telehealth visits.</li>
                </ul>
              </div>
            </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="row mt-5">
                          <div className="col-lg-6">
                              <Img fluid={img_vip} className="section2-img1-mobile" />
                              <ul>
                                  <li className="text-white">VIP airport assistance is a premium service offered as add-on to travelers that provides personalized, high-end support throughout the airport experience, when requested by the member and organized by INF-Robin Assist. This can include amenities such as fast-track immigration and security, private transportation to and from the plane, exclusive lounge access, and assistance with baggage and check-in. The goal of VIP airport assistance is to make the travel experience as seamless and comfortable as possible, while providing a high level of personal attention and luxury. There may be a fee associated with this service. This is an assistance feature and is not insurance. </li>
                              </ul>
                          </div>
                          <div className="col-lg-6">
                              <Img fluid={img_vip} className="section2-img1" />
                          </div>
                      </div>
                  </div>
                  <div className="col-lg-12">
                      <div className="row mt-5">
                          <div className="col-lg-5">
                              <Img fluid={img_smartdelay} className="section2-img2" />
                          </div>
                          <div className="col-lg-7">
                              <ul>
                                  <li className="text-white">Airport delays and disruptions can be tiring and frustrating, and travellers rarely have access to immediate ways of reducing the stress caused. SmartDelay is offered to our members as an ideal solution for flight delays. Discover a different approach to assistance by offering an instantly redeemable benefit that eases the pain of flight delays, while enhancing our member experience. With SmartDelay, members can access a space that provides comfort, complimentary food and drink, and free Wi-Fi while they wait for their delayed flight for more than 90 minutes. This is an assistance feature and is not insurance.</li>
                              </ul>
                          </div>
                      </div>
                  </div>
        </Container>
      </section>

      <section id="standard-section3">
        <Container>
          <div className="col-lg-12">
            <h2 className="text-blue text-center">Why Choose Hop! WorldHopper?</h2>
            <p className="text-black text-center tab-row-title">At Hop!, we understand that unexpected situations can arise while traveling, which is why we offer a range of services to help you navigate any travel-related issues that may come your way. Here are some of the features we offer to ensure that your travel plans stay on track:</p>
            <div>
            <h2 className="text-blue text-center">Benefits</h2>
            <table className="table align-middle text-center fs12 blue-table">
        <tbody><tr>
          <th colSpan={6}>Travel Assistance while Traveling Outside Your Home Country</th>
        </tr>
        <tr>
          <td>Maximum Assistance</td>
          <td>$150,000</td>
          <td>$250,000</td>
          <td>$300,000</td>
          <td>$500,000</td>
          <td>$1,000,000</td>
        </tr>
        <tr>
          <td>Trip Interruption (Death of Immediate Family Member) (Optional)</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
        </tr>
        <tr>
          <td>Trip Interruption (Serious Disaster at Home) (Optional)</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
          <td>Economy Ticket</td>
        </tr>
        <tr>
          <td>Trip Cancellation (up to age 74) (Optional)</td>
          <td>N/A</td>
          <td>$2,000</td>
          <td>$2,000</td>
          <td>$2,000</td>
          <td>$2,200</td>
        </tr>
        <tr>
          <td>Delay or Cancelled Flight (Optional)</td>
          <td>NA</td>
          <td>NA</td>
          <td>$150</td>
          <td>$150</td>
          <td>$300</td>
        </tr>
        <tr>
          <td>Missed Connection / Coordination (Optional)</td>
          <td>$100</td>
          <td>$200</td>
          <td>$450</td>
          <td>$500</td>
          <td>$550</td>
        </tr>
        <tr>
          <td>Baggage Delay / Lost Bag Search (Optional)</td>
          <td>$200</td>
          <td>$400</td>
          <td>$600</td>
          <td>$800</td>
          <td>$1,000</td>
        </tr>
        <tr>
          <td>Lost Baggage / Lost Bag Search (Optional)</td>
          <td>$300</td>
          <td>$600</td>
          <td>$900</td>
          <td>$1,200</td>
          <td>$1,500</td>
        </tr>
        <tr>
          <td>Flight Rebooking</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>Hotel Rebooking</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>Rental vehicle booking</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>Lost Passport &amp; Travel Docs (Optional)</td>
          <td>$40</td>
          <td>$60</td>
          <td>$60</td>
          <td>$90</td>
          <td>$90</td>
        </tr>
        <tr>
          <td>Emergency Cash Transfer (Optional)</td>
          <td>$5,000</td>
          <td>$5,000</td>
          <td>$5,000</td>
          <td>$5,000</td>
          <td>$5,000</td>
        </tr>
        <tr>
          <td>Up-to-the-minute Travel Delay Reports</td>
          <td colSpan={5}>INCLUDED</td>
        </tr>
      </tbody></table>
            </div>

            <div>
            <table className="table align-middle text-center fs12 blue-table">
        <tbody><tr>
          <th colSpan={6}>Security Assistance while Traveling Outside Your Home Country</th>
        </tr>
        <tr>
          <td>Malicious product tamper and contamination</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>General travel risk and travel security</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>Assistance in mass public disturbances, violent terrorist, or extremist incidents</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>24/7 point of contact to report emergencies and get security and safety advice from duty managers</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>Assistance in cases of major disasters</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>Emergency political and security evacuations (Optional)</td>
          <td>$10,000</td>
          <td>$15,000</td>
          <td>$20,000</td>
          <td>$30,000</td>
          <td>$30,000</td>
        </tr>
        <tr>
          <td>Hijacking (any form of conveyance) (Optional)</td>
          <td>$10,000</td>
          <td>$15,000</td>
          <td>$20,000</td>
          <td>$30,000</td>
          <td>$30,000</td>
        </tr>
        <tr>
          <td>Illegal detention and arrest (Optional)</td>
          <td colSpan={5}>Available</td>
        </tr>
        <tr>
          <td>Kidnap for ransom (Optional)</td>
          <td>$10,000</td>
          <td>$15,000</td>
          <td>$20,000</td>
          <td>$30,000</td>
          <td>$30,000</td>
        </tr>
        <tr>
          <td>Monitoring and telephone advice throughout the crisis</td>
          <td colSpan={5}>Available</td>
        </tr>
      </tbody></table>
            </div>

            <div>
            <table className="table align-middle text-center fs12 blue-table">
        <tbody><tr><th colSpan={2}>Medical Assistance while Traveling Outside Your Home Country</th></tr>
        <tr>
        <td>Ground Ambulance</td>
        <td>Available</td>
        </tr>
        <tr><td>Air Ambulance</td>
        <td>$50,000 (Optional)</td>
        </tr>
        <tr><td>Commercial Airline (including medical stretcher)</td>
        <td>$20,000</td>
        </tr>
        <tr><td>Medical clearances &amp; Fit-to-Fly Assessments</td>
        <td>Available</td>
        </tr>
        <tr><td>Physician, hospital, dental, and vision referrals via Hop Assist</td>
        <td>Available</td>
        </tr>
        <tr><td>Medical Cost containment, expense recovery, and overseas investigation</td>
        <td>Available</td>
        </tr>
        <tr><td>Arrangement of visitor to bedside</td>
        <td>Available</td>
        </tr>
        <tr><td>Medical Payment Arrangements</td>
        <td>Available</td>
        </tr>
        <tr><td>Medical Bill Audit</td>
        <td>Available</td>
        </tr>
        <tr><td>Shipment of Medical Records (Optional)</td>
        <td>$75</td>
        </tr>
        <tr><td>Medical Equipment Rental &amp; Replacement</td>
        <td>$1000</td>
        </tr>
        <tr><td>In-Patient / Out-Patient Care Management</td>
        <td>Available</td>
        </tr>
        <tr><td>Eyeglasses &amp; Corrective lens replacement</td>
        <td>Available</td>
        </tr>
        <tr><td>Medical Assistance for Accident &amp; Sickness via Hop Assist</td>
        <td>Options: $150,000, $250,000, $300,000, $500,000, or $1,000,000</td>
        </tr>
        <tr><td>Medical Assistance for Pre-existing Conditions:</td>
        <td>$25,000, $30,000, $40,000 or $50,000 subject to a $1,500, $2,000, $2,500, $3,000, $5,000 or $10,000 medical assistance access expense</td>
        </tr>
        <tr><td>Medical Assistance Access Expense:</td>
        <td>Options: $100, $250, $500, $1,000, $2,500, or $5,000</td>
        </tr>
        <tr><td>Medical Assistance Payment Rate</td>
        <td>100% to the Medical Assistance Benefit Chosen</td>
        </tr>
        <tr><td>Urgent Care Visits</td>
        <td>$25 Medical Access Expense</td>
        </tr>
        <tr><td>Accidental Death and Dismemberment Benefit:</td>
        <td>$25,000</td>
        </tr>
        <tr><td>Trip Interruption Benefit</td>
        <td>$10,000</td>
        </tr>
        <tr><td>Emergency Medical Evacuation Benefit:</td>
        <td>Up to $25,000</td>
        </tr>
        <tr><td>Repatriation of Remains Benefit:</td>
        <td>Up to $25,000</td>
        </tr>
      </tbody></table>
            </div>
          </div>
        </Container>
      </section>

      
      <section id="standard-section5">
        <Container>
          <div className="col-lg-12">
            <h2 className="text-blue text-center">Hop! WorldHopper General Conditions</h2>
          </div>
          <div className="col-lg-12 ">
            <div className="row mt-4 justify-content-center">
              <div className="col-lg-5 text-center">
                <h3 className="text-black">Read More for Age 0-69 </h3>
                <Img fluid={book_icon} className="book-icon-img"/>
                <a href="/policy_pdf/Hop_WorldHopper_Assist_General_Conditions.pdf" target="_blank">
                  <button className="read-now-btn"> Read Now </button>
                </a>
              </div>
              <div className="col-lg-5 text-center">
                <h3 className="text-black">Read More for Age 70-99 </h3>
                <Img fluid={book2_icon} className="book-icon-img"/>
                              <a href="/policy_pdf/Hop_WorldHopper_Assist_General_Conditions.pdf" target="_blank">
                  <button className="read-now-btn"> Read Now </button>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <BackgroundImage
        Tag="section"
        className="standard-cta-hero-section-bg"
        fluid={cta_bg}
        alt="cta"
      >
        <div id="standard-cta-section">
          <Container>
            <div className="col-lg-12">
              <h2 className="text-white text-center">Get Coverage Now</h2>
              <Link to="/apply"><button className="buy-now-btn"> Apply Now </button></Link>
            </div>
          </Container>
        </div>
      </BackgroundImage>

      <section id="standard-section7">
        <Container>
                    <div className="col-lg-12">
            <div className="blue-bg text-center">
              <h3 className="text-white">Privacy Statement:</h3>
              <p className="text-white">We know that your privacy is important to you and we strive to protect the confidentiality of your non-public personal information. We do not disclose any non-public personal information about our insureds or former insureds to anyone, except as permitted or required by law. We maintain appropriate physical, electronic and procedural safeguards to ensure the security of your non-public personal information. You may obtain a detailed copy of our privacy policy by calling 408-222-1110.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="white-bg text-center">
              <h3 className="text-blue">Complaints:</h3>
              <p className="text-black">In the event that you remain dissatisfied and wish to make a complaint you can do so to the Complaints team at {emailAddress}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="blue-bg text-center">
              <h3 className="text-white">Data Protection:</h3>
              <p className="text-white">Please note that sensitive health and other information that you provide may be used by us, our representatives, the insurers and industry governing bodies and regulators to process your insurance, handle claims and prevent fraud. This may involve transferring information to other countries (some of which may have limited, or no data protection laws). We have taken steps to ensure your information is held securely. Where sensitive personal information relates to anyone other than you, you must obtain the explicit consent of the person to whom the information relates both to the disclosure of such information to us and its use as set out above. Information we hold will not be shared with third parties for marketing purposes. You have the right to access your personal records.</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="white-bg text-center">
              <h3 className="text-blue">Note:</h3>
              <p className="text-black">This travel assistance program is not an insurance and is not subject to and does not provide any insurance benefits required by the United States’ Patient Protection and Affordable Care Act (“PPACA”). This coverage is not a general health insurance product in any manner or kind. 
       </p>
     </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default StandardPage

export const query = graphql`
  query WorldhopperPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        phoneNumber
        emailAddress
      }
    },
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            author
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxHeight: 362) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    },
    hero_background: file(name: { eq: "standard-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    infplans_big_logo: file(name: { eq: "infplans-big-logo" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_img_right: file(name: { eq: "standard-section2-img-right" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_img_left: file(name: { eq: "standard-section2-img-left" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book_icon: file(name: { eq: "book-icon" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    book2_icon: file(name: { eq: "book2-icon" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    cta_bg: file(name: { eq: "cta-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_doctor: file(name: { eq: "doctor" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_smartdelay: file(name: { eq: "smartdelay" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_vip: file(name: { eq: "vip_airport" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_hero: file(name: { eq: "travel_girl" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    img_worldhopper: file(name: { eq: "worldhopper1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
